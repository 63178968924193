<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'createMenu',
})
</script>
<template>
  <a-modal
    :title="state.modalTitle"
    width="80%"
    :open="true"
    :confirmLoading="state.loading"
    @ok="onFormSubmit"
    @cancel="
      () => {
        emit('cancel')
      }
    "
  >
    <a-spin :spinning="state.loading">
      <a-form ref="ruleForm" :model="formState" v-bind="formLayout" :rules="rules">
        <a-form-item label="用户名" name="username">
          {{ props.detailData.nickname }}
          <!-- {{ state.options }}
          <hr />
          {{ formState }} -->
        </a-form-item>
        <a-form-item label="平台" name="platform_id">
          <a-select @change="selectPlatformChange" style="width: 100%" :getPopupContainer="(triggerNode) => triggerNode.parentNode" v-model:value="formState.platform_id" placeholder="请选择平台简称">
            <a-select-option class="relative" v-for="(item, index) in state.platformServicesList" :key="index" :value="item.id" :title="item.platform_name">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <div v-if="formState.platform_id">
          <a-form-item label="状态">
            <a-switch :checkedValue="1" :unCheckedValue="2" v-model:checked="formState.status"></a-switch>
          </a-form-item>
          <a-form-item label="时间范围" name="between_time">
            <a-range-picker :show-time="{ defaultValue: arrayMonent }" :value="rangTime" @change="rangTimeChange" :format="format"></a-range-picker>
          </a-form-item>
          <a-form-item label="公司前缀" name="company_prefix">
            <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" style="width: 400px" placeholder="请选择" v-model:value="formState.company_prefix" mode="multiple">
              <a-select-option v-for="(item, index) in state.options.company_address_number_prefix" :key="index" :value="item"> {{ item }} </a-select-option>
            </a-select>
            <span> 置空则按平台规则</span>
          </a-form-item>
          <a-form-item label="个体前缀" name="individual_prefix">
            <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" style="width: 400px" placeholder="请选择" v-model:value="formState.individual_prefix" mode="multiple">
              <a-select-option v-for="(item, index) in state.options.individual_address_number_prefix" :key="index" :value="item"> {{ item }} </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="是否开启短信通知" name="smsflag">
            <a-radio-group button-style="solid" v-model:value="formState.smsflag">
              <a-radio-button :value="0">跟随平台</a-radio-button>
              <a-radio-button :value="1">开启</a-radio-button>
              <a-radio-button :value="2">关闭</a-radio-button>
            </a-radio-group>
          </a-form-item>
          <a-form-item label="手机号码实名认证" name="shiming_flag">
            <a-radio-group button-style="solid" v-model:value="formState.shiming_flag">
              <a-radio-button :value="0">跟随平台</a-radio-button>
              <a-radio-button :value="1">开启</a-radio-button>
              <a-radio-button :value="2">关闭</a-radio-button>
            </a-radio-group>
          </a-form-item>
          <a-form-item v-if="formState.shiming_flag == 1" label="核验手机号码时间">
            <a-input style="width: 200px" addonAfter="月" v-model:value="formState.shiming_telusetime"></a-input>
          </a-form-item>
          <a-form-item label="未付款数量最多">
            <a-input style="width: 200px" addonAfter="家" v-model:value="formState.unpaid_num"></a-input>
            <span> 0为按平台规则</span>
          </a-form-item>
          <a-form-item label="未办结数量最多">
            <a-input style="width: 200px" addonAfter="家" v-model:value="formState.unfinished_num"></a-input>
            <span> 0为按平台规则</span>
          </a-form-item>
          <a-form-item label="账期天数">
            <a-input style="width: 200px" addonAfter="天" v-model:value="formState.period_days"></a-input>
            <span> 0为按平台规则</span>
          </a-form-item>
          <a-form-item label="万能支付权限">
            <a-switch :checkedValue="1" :unCheckedValue="2" v-model:checked="formState.universal_payment"></a-switch>
          </a-form-item>
        </div>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script lang="ts" setup name="createMenu">
import { reactive, ref, onMounted, computed, watch, nextTick } from 'vue'
import { message } from 'ant-design-vue'
import baseService from '@/utils/http/axios'
import type { TreeProps } from 'ant-design-vue'
import dayjs from 'dayjs'
const format = 'YYYY-MM-DD HH:mm:ss'
const arrayMonent = [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')]

const props = defineProps({
  detailData: {
    type: Object,
    default: () => null
  }
})
const emit = defineEmits(['cancel', 'ok'])
const ruleForm = ref()
const rules = {
  permissions: [{ required: true, message: '请选择菜单权限', trigger: 'change' }]
}
const fieldNames = {
  title: 'display_name',
  key: 'id',
  children: '_child'
}
const formLayout = {
  labelCol: {
    xs: { span: 6 },
    sm: { span: 6 }
  },
  wrapperCol: {
    xs: { span: 15 },
    sm: { span: 15 }
  }
}
interface platformServicesList {
  id: number
  name: string
  company_area_name: string
  company_city_name: string
  platform_name: string
  company_province_name: string
}
interface state {
  modalTitle: string
  loading: boolean
  unfold: boolean
  checkAll: boolean
  permissionList: any
  expandedKeys: (string | number)[]
  platformServicesList: platformServicesList[]
  options: any
}
const state = reactive<state>({
  modalTitle: '编辑用户平台权限',
  loading: false,
  unfold: false,
  checkAll: false,
  permissionList: [],
  expandedKeys: [],
  // 平台选项信息
  platformServicesList: [],
  options: {} //选中的平台,选项
})
interface formState {
  platform_id: number | null
  status: number
  between_time: string | null
  company_prefix: string[]
  individual_prefix: string[]
  smsflag: number
  shiming_flag: number
  shiming_telusetime: number
  unpaid_num: number
  unfinished_num: number
  period_days: number
  universal_payment: number
}
let formState = reactive<formState>({
  platform_id: null,
  status: 1,
  between_time: '',
  company_prefix: [], //公司前缀
  individual_prefix: [], //个体前缀
  smsflag: 0, //是否开启短信通知
  shiming_flag: 0, //手机号码实名认证
  shiming_telusetime: 0, //核验手机号码时间
  unpaid_num: 0,
  unfinished_num: 0,
  period_days: 0,
  universal_payment: 2 //开启万能支付
})
onMounted(() => {
  // 获取平台信息
  queryCompanyTypeList()
})
// 获取平台信息
const queryCompanyTypeList = () => {
  let requestParam = {
    url: '/admin/user/platform',
    method: 'post'
  }
  baseService(requestParam)
    .then((res) => {
      state.platformServicesList = res.data
      //如果已经指定编辑的平台
      if (props.detailData.platform_id) {
        selectPlatformChange(null, { value: props.detailData.platform_id })
      }
    })
    .catch((res) => {
      if (res.msg) {
        message.error(res.msg)
      }
    })
}
// 选择平台之后
const selectPlatformChange = (res, e) => {
  formState.platform_id = e.value
  state.loading = true
  // 获取平台选项信息，并设置已选中的默认值
  let requeryParam = {
    url: `/admin/user/platformSet`,
    method: 'post',
    data: { userId: props.detailData.id, platformId: e.value }
  }
  baseService(requeryParam)
    .then((res: any) => {
      state.loading = false
      state.options = res.data.options
      Object.assign(formState, res.data.form)
    })
    .catch((res) => {
      state.loading = false
      if (res.msg) {
        message.error(res.msg)
      } else {
        message.error('操作失败')
      }
    })
}
// 时间选择
const rangTime = computed(() => {
  let array: any = []
  if (formState.between_time) {
    array = formState.between_time.split(',').map((item) => dayjs(item ? item : null, format))
  } else {
    array = []
  }
  return array
})
// 时间选择
const rangTimeChange = (val, str) => {
  if (val) {
    formState.between_time = str.join(',')
  } else {
    formState.between_time = null
  }
}

const onFormSubmit = () => {
  if (!formState.platform_id) {
    message.error('请选择平台')
    return
  }
  ruleForm.value.validate().then(() => {
    state.loading = true
    let requeryParam = {
      url: `/admin/user/platformSetSave`,
      method: 'post',
      data: Object.assign(formState, { userId: props.detailData.id })
    }
    baseService(requeryParam)
      .then((res: any) => {
        state.loading = false
        message.success('操作成功')
        emit('ok')
      })
      .catch((res) => {
        state.loading = false
        if (res.msg) {
          message.error(res.msg)
        } else {
          message.error('操作失败')
        }
      })
  })
}
</script>

<style lang="less" scoped>
.tree {
  margin-top: 10px;
  border: 1px solid #d9d9d9;
}
</style>
