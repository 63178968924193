<template>
  <a-modal
    :title="state.modalTitle"
    :width="1200"
    :open="true"
    @ok="onFormSubmit"
    :confirmLoading="state.loading"
    @cancel="
      () => {
        emit('cancel')
      }
    "
  >
    <template #footer>
      <a-button
        size="middle"
        @click="
          () => {
            emit('cancel')
          }
        "
        >取消</a-button
      >

      <a-button size="middle" type="primary" @click="onFormSubmit()" :loading="state.finishLoading" :disabled="state.finishLoading">确定</a-button>
    </template>
    <a-spin :spinning="loading">
      <a-form ref="formRef" v-bind="formLayout" :model="formState" :rules="rules">
        <a-form-item required label="工单标题" name="title">
          <a-input v-model:value="formState.title" placeholder="请输入工单标题"></a-input>
        </a-form-item>
        <a-form-item label="工单类型" name="type">
          <a-radio-group v-model:value="formState.type" button-style="solid" @change="change">
            <a-radio-button :value="3">代办事项</a-radio-button>
            <a-radio-button :value="1">投诉</a-radio-button>
            <a-radio-button :value="2">举报</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item required label="信息来源情况" name="source" v-if="formState.type == 1 || formState.type == 2">
          <a-input v-model:value="formState.source" placeholder="请输入信息来源情况"></a-input>
        </a-form-item>
        <a-form-item label="工单类型" required>
          <a-select ref="select" v-model:value="formState.wo_type">
            <a-select-option :value="1">经营纠纷</a-select-option>
            <a-select-option :value="2">网络卖课</a-select-option>
            <a-select-option :value="3">疑似诈骗</a-select-option>
            <a-select-option :value="4">疑似银行类犯罪</a-select-option>
            <a-select-option :value="5">其它类型</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="工单服务对象" v-if="state.isEdit">
          {{ formState.customer_info['full_name'] }}
        </a-form-item>
        <a-form-item label="工单服务对象" v-if="!state.isEdit">
          <SelectCompany :isEdit="false" :selectCheck="formState.customer_ids" @change="SelectCompanyChange"></SelectCompany>
        </a-form-item>
        <a-form-item label="工单处理接收人" name="recipient" required>
          <a-select
            v-model:value="formState.recipient"
            mode="multiple"
            label-in-value
            placeholder="请输入工单处理接收人姓名或手机号码"
            style="width: 100%"
            :filter-option="false"
            :not-found-content="gdstate.fetching ? undefined : null"
            :options="gdstate.data"
            @search="fetchUser"
          >
            <template v-if="gdstate.fetching" #notFoundContent>
              <a-spin size="small" />
            </template>
          </a-select>
        </a-form-item>
        <a-form-item label="工单办结监督责任人" name="custodian" required>
          <a-select
            v-model:value="formState.custodian"
            mode="multiple"
            label-in-value
            placeholder="请输入工单办结监督责任人姓名或手机号码"
            style="width: 100%"
            :filter-option="false"
            :not-found-content="gdstate.fetching ? undefined : null"
            :options="gdstate.data"
            @search="fetchUser"
          >
            <template v-if="gdstate.fetching" #notFoundContent>
              <a-spin size="small" />
            </template>
          </a-select>
        </a-form-item>
        <a-form-item label="开始时间" name="start_time" required>
          <a-date-picker :show-time="{ defaultValue: arrayMonent }" v-model:value="formState.start_time" placeholder="请选择开始日期"></a-date-picker>
        </a-form-item>
        <a-form-item label="截止完成时间" name="end_time" required>
          <a-date-picker :show-time="{ defaultValue: arrayMonent }" v-model:value="formState.end_time" placeholder="请选择截止日期"></a-date-picker>
        </a-form-item>
        <a-form-item label="工单内容" name="content">
          <a-textarea :rows="3" placeholder="请输入工单内容" v-model:value="formState.content"> </a-textarea>
        </a-form-item>
        <a-form-item label="机密内容">
          <a-textarea placeholder="请输入机密内容" v-model:value="formState.secret_content" :rows="3"> </a-textarea>
        </a-form-item>

        <a-form-item label="附件">
          <Upload
            upload_path="public/uploads/工单"
            :accept="['pdf', 'doc', 'docx', 'xls', 'xlsx', 'jpeg', 'jpg', 'png']"
            v-model:value="formState.attachment"
            uploadType="text"
            :limitation="9"
            uploadText="上传附件"
          >
          </Upload>
        </a-form-item>
        <a-form-item label="紧急情况">
          <a-radio-group button-style="solid" v-model:value="formState.critical_situation">
            <a-radio-button :value="1">不急</a-radio-button>
            <a-radio-button :value="2">普通</a-radio-button>
            <a-radio-button :value="3">紧急</a-radio-button>
            <a-radio-button :value="4">最高级</a-radio-button>
          </a-radio-group>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script lang="ts" setup>
import cloneDeep from 'lodash.clonedeep'
import { Upload, SelectCompany } from '@/components'
import baseService from '@/utils/http/axios'
import { message } from 'ant-design-vue'
import { ref, reactive, onMounted, watch } from 'vue'
import { debounce } from 'lodash-es'
import dayjs from 'dayjs'
const emit = defineEmits(['cancel', 'ok'])
const arrayMonent = dayjs('00:00:00', 'HH:mm:ss')
const format = 'YYYY-MM-DD HH:mm:ss'
const startTime = dayjs()
const endTime = dayjs().add(1, 'day')

const rules = {
  type: [
    {
      required: true,
      message: '请选择工单类型'
    }
  ],
  content: [
    {
      required: false,
      message: '请输入工单内容'
    }
  ]
}
interface formState {
  title: string
  source: string
  customer_info: object
  type: number | null
  wo_type: number | null
  customer_ids: any[]
  start_time: any
  end_time: any
  content: string
  custodian: any[]
  recipient: any[]
  secret_content: string
  attachment: any[]
  critical_situation: number
}
const formState = reactive<formState>({
  title: '',
  source: '',
  customer_info: [],
  type: 3,
  wo_type: 1,
  customer_ids: [],
  critical_situation: 2,
  end_time: dayjs().subtract(-2, 'day'),
  start_time: startTime,
  content: '',
  custodian: [],
  recipient: [],
  secret_content: '',
  attachment: []
})
const props = defineProps({
  detailData: {
    type: Object
  },
  loading: {
    type: Boolean,
    default: () => false
  },
  model: {
    type: Object,
    default: () => null
  },
  counterfeitInfo: {
    type: Object,
    default: () => null
  }
})
const formLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 7 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 13 }
  }
}
onMounted(() => {
  if (props.detailData?.id) {
    state.isEdit = true
    state.modalTitle = '编辑工单'
    queryDetail(props.detailData.id)
  }
  if (props.counterfeitInfo) {
    formState.customer_ids.push({ id: props.counterfeitInfo.customer_id, name: props.counterfeitInfo.full_name })
    formState.recipient.push({'label':props.counterfeitInfo.seller,'value':props.counterfeitInfo.seller_id})
    if (props.counterfeitInfo.platform_store_manager_info?.length > 0) {
      props.counterfeitInfo.platform_store_manager_info.forEach((element) => {
        if (element.nickname) {
          formState.custodian.push(element.nickname)
        }
      })
    }
  }
})
const queryDetail = (id) => {
  state.loading = true
  let requeryParam = {
    url: `/admin/v1/workOrder/${id}/edit`,
    method: 'get'
  }
  baseService(requeryParam).then((res) => {
    state.loading = false
    res.data.end_time = dayjs(res.data.end_time, format)
    res.data.start_time = dayjs(res.data.start_time, format)
    Object.assign(formState, res.data)
  })
}

const formRef = ref()
const state = reactive<any>({
  loading: false,
  isEdit: false,
  modalTitle: '新增工单',
  userList: [],
  finishLoading: false
})
const gdstate = reactive<any>({
  data: [],
  value: [],
  fetching: false
})
let lastFetchId = 0
const fetchUser = debounce((value) => {
  lastFetchId += 1
  const fetchId = lastFetchId
  gdstate.data = []
  gdstate.fetching = true
  let requestParam = {
    url: '/admin/user/publicList',
    method: 'post',
    data: { keyword: value }
  }
  baseService(requestParam).then((body) => {
    if (fetchId !== lastFetchId || body.data == null || body.data == '') {
      return
    }

    gdstate.data = [
      {
        label: body.data.nickname + ' ' + body.data.phone,
        value: body.data.id
      }
    ]
    gdstate.fetching = false
  })
}, 300)

watch(gdstate.value, () => {
  gdstate.data = []
  gdstate.fetching = false
})
const SelectCompanyChange = (val: any[]) => {
  formState.customer_ids = val.map((res) => {
    return { id: res.id, name: res.name }
  })
}
const change = (val) => {
  if (val.target.value == 1) {
    formState.critical_situation = 3
  } else if (val.target.value == 2) {
    formState.critical_situation = 4
  } else {
    formState.critical_situation = 2
  }
}

const onFormSubmit = () => {
  formRef.value
    .validate()
    .then((errors, values) => {
      let data = cloneDeep(formState)
      data.start_time = dayjs(data.start_time).format(format)
      data.end_time = dayjs(data.end_time).format(format)
      if (props.detailData?.id) {
        let requeryParam = {
          url: '/admin/v1/workOrder/' + props.detailData.id,
          method: 'put',
          data: data
        }
        state.loading = true
        state.finishLoading = true
        baseService(requeryParam)
          .then((res: any) => {
            message.success(res.msg)
            emit('ok')
            state.loading = false
            state.finishLoading = false
          })
          .catch((res) => {
            state.loading = false
            state.finishLoading = false
            if (res.data) {
              message.warning('参数错误,请检查')
            } else {
              message.warning(res.msg)
            }
          })
      } else {
        let requeryParam = {
          url: '/admin/v1/workOrder',
          method: 'post',
          data: data
        }
        state.loading = true
        state.finishLoading = true
        baseService(requeryParam)
          .then((res: any) => {
            message.success(res.msg)
            emit('cancel')
            state.loading = false
            state.finishLoading = false
          })
          .catch((res) => {
            state.loading = false
            state.finishLoading = false
            if (res.data) {
              message.warning('参数错误,请检查')
            } else {
              message.warning(res.msg)
            }
          })
      }
    })
    .catch((res) => {
      message.warning('请检查表单必填项')
    })
}
</script>

<style lang="less" scoped>
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.add-button {
  position: absolute;
  right: 65px;
  top: 10px;
}

.positionItem {
  display: inline-block;
  margin-bottom: 10px;
}
</style>
