import hasRole from './permission/hasRole'
import hasPermi from './permission/hasPermi'
import hasUser from './permission/hasUser'
/**
 * 用法
 * // 单个
<el-button v-hasPermi="['system:user:add']">存在权限字符串才能看到</el-button>
// 多个
<el-button v-hasPermi="['system:user:add', 'system:user:edit']">包含权限字符串才能看到</el-button>
 * @param Vue
 */
export default function directive(app): void {
    app.directive('hasRole', hasRole)
    app.directive('hasPermi', hasPermi)
    app.directive('hasUser', hasUser)
}
