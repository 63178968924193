<template>
  <div>
    <!-- 输入框 -->
    <a-input :type="type" v-model:value="state.phone" @blur="inputEnterBlur" @pressEnter="inputEnterBlur" :placeholder="props.placeholder">
      <template #suffix>
        <a-tooltip :title="props.suffixTitle">
          <InfoCircleOutlined style="color: rgba(0, 0, 0, 0.45)" />
        </a-tooltip>
      </template>
      <!-- 输入框后缀提示 -->
    </a-input>
    <div>
      <!-- 手机号列表 -->
      <div v-for="(item, index) in state.phoneList" :key="index" class="phone-item" style="margin-bottom: 20px">
        <a-row>
          <a-col :span="8">
            <div style="margin-top: 5px">{{ item.name }}</div>
          </a-col>
          <a-col :span="14">
            <div>
              <a-form-item name="默认授权" class="phone-item-margin">
                <a-checkbox v-model:checked="item.default">默认授权给所有授权用户</a-checkbox>
              </a-form-item>
            </div>
          </a-col>
          <a-col :span="2">
            <!-- 删除按钮 -->
            <DeleteOutlined style="cursor: pointer; margin-top: 9px" @click="deleteClick(index)" />
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="6">包注册价格↓</a-col>
          <a-col :span="6">裸地址价格↓</a-col>
          <a-col :span="6">一次性价格↓</a-col>
          <a-col :span="6">续费价格↓</a-col>
        </a-row>
        <a-row>
          <a-col :span="6">
            <a-input placeholder="包注册价格" v-model:value="item.price1"></a-input>
          </a-col>
          <a-col :span="6"><a-input placeholder="裸地址价格" v-model:value="item.price2"></a-input></a-col>
          <a-col :span="6"><a-input placeholder="一次性价格" v-model:value="item.price3"></a-input></a-col>
          <a-col :span="6"><a-input placeholder="续费价格" v-model:value="item.price4"></a-input></a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { reactive, watch } from 'vue'
import { message } from 'ant-design-vue'
import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons-vue'
const emit = defineEmits(['update:value', 'change'])
const props = defineProps({
  value: {
    type: Array
  },
  placeholder: {
    type: String
  },
  suffixTitle: {
    type: String
  },
  inputType: {
    type: String
  },
  type: {
    type: String,
    default: 'text'
  }
})
interface state {
  phone: string
  phoneList: { name: string; default: boolean; price1: number | undefined; price2: number | undefined; price3: number | undefined; price4: number | undefined }[]
}
const state = reactive<state>({
  phone: '',
  phoneList: []
})
watch(
  () => props.value,
  (val) => {
    if (val && val.length > 0) {
      state.phoneList = val as any
    }
  },
  {
    deep: true,
    immediate: true
  }
)
// 验证手机号码格式
const validatePhoneNumber = (phoneNumber) => {
  var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
  return reg_tel.test(phoneNumber)
}
// 添加手机号码
const addPhoneNumber = (phoneNumber: string) => {
  if (phoneNumber && phoneNumber.length > 0) {
    if (props.inputType == 'number') {
      if (!validatePhoneNumber(phoneNumber)) {
        message.error('请输入正确的手机号码')
        return
      }
    }
    if (!state.phoneList.some((item: any) => item.name == phoneNumber)) {
      let line = { name: phoneNumber, default: false }
      state.phoneList.push(line)
    }
    state.phone = ''
    emit('update:value', state.phoneList)
    emit('change', state.phoneList)
  }
}
//
const inputEnterBlur = (val) => {
  addPhoneNumber(val.target.value)
}
// 删除手机号码
const deleteClick = (index) => {
  state.phoneList.splice(index, 1)
  emit('update:value', state.phoneList)
}
</script>

<style lang="less" scoped>
.phone-item {
  display: 100%;
  align-items: center;
  justify-content: space-between;
}
.phone-item-margin {
  margin-bottom: 0px;
  padding-bottom: 0px;
}
</style>
