<template>
  <div>
    <div style="display: flex">
      <a-form-item-rest v-if="props.isFiliale == 1">
        <a-input
          @change="
            (res) => {
              inputChange(res, 'filiale_full_name')
            }
          "
          v-model:value="state.filiale_full_name"
          placeholder="总公司名称"
        ></a-input>
      </a-form-item-rest>
      <a-form-item-rest v-if="props.isFiliale != 1">
        <a-input
          @change="
            (res) => {
              inputChange(res, 'name_division')
            }
          "
          v-model:value="state.name_division"
          placeholder="名称区划"
        ></a-input>
      </a-form-item-rest>
      <a-form-item-rest>
        <a-input
          @change="
            (res) => {
              inputChange(res, 'name_word_mark')
            }
          "
          v-model:value="state.name_word_mark"
          :placeholder="props.isFiliale == 1 ? '地域或字号:第一，湖南，长沙市' : '字号'"
        ></a-input>
      </a-form-item-rest>
      <a-form-item-rest v-if="props.isFiliale != 1">
        <a-select
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          optionLabelProp="label"
          popupClassName="principalName"
          :value="state.ondustry_representation_id"
          show-search
          placeholder="行业表述"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          @search="handleSearch"
          @change="handleChange"
        >
          <a-select-option v-for="(item, index) in state.options" :key="index" :title="item.keyvalue" :value="item.id" :label="item.keyname">
            <div class="d-flex">
              <span style="width: 160px">
                {{ item.keyname }}
              </span>
              <!-- <span class="flex-1">
                <span class="top"> 置顶 </span>
              </span> -->
              <span style="width: 230px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; font-size: 12px"> (行业：{{ item.keyvalue }}) </span>
            </div>
          </a-select-option>
        </a-select>
      </a-form-item-rest>
      <a-form-item-rest v-if="props.principalType == 1">
        <a-select
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :value="state.organizational_form"
          @change="
            (res) => {
              selectChange(res, 'organizational_form')
            }
          "
          placeholder="请选择"
        >
          <a-select-option v-for="(item, index) in state.optionList" :key="index" :value="item">
            {{ item }}
          </a-select-option>
        </a-select>
      </a-form-item-rest>
      <a-form-item-rest v-if="props.principalType == 2">
        <a-select
          :value="state.organizational_form"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          @change="
            (res) => {
              selectChange(res, 'organizational_form')
            }
          "
          placeholder="组织形式"
        >
          <a-select-option v-for="(item, index) in state.organizationalFormList" :key="index" :value="item">
            {{ item }}
          </a-select-option>
        </a-select>
      </a-form-item-rest>
      <a-form-item-rest v-if="props.principalType == 3">
        <a-select
          :value="state.organizational_form"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          @change="
            (res) => {
              selectChange(res, 'organizational_form')
            }
          "
          placeholder="组织形式"
        >
          <a-select-option v-for="(item, index) in state.organizationalFormList_GD" :key="index" :value="item">
            {{ item }}
          </a-select-option>
        </a-select>
      </a-form-item-rest>
      <a-form-item-rest v-if="props.principalType == 4">
        <a-select
          :value="state.organizational_form"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          @change="
            (res) => {
              selectChange(res, 'organizational_form')
            }
          "
          placeholder="组织形式"
        >
          <a-select-option v-for="(item, index) in state.hehuoList" :key="index" :value="item">
            {{ item }}
          </a-select-option>
        </a-select>
      </a-form-item-rest>
    </div>
    <div style="margin-top: 5px">
      主体名称预览：
      <span
        style="color: red; cursor: pointer"
        @click="
          (event) => {
            handleNameClick(event)
          }
        "
        >{{ state.full_name }}</span
      ><a @click="change_zihao_postion" class="ml-3x" v-if="props.principalType == 1 && state.name_division && state.name_word_mark && state.ondustry_representation && state.organizational_form"
        >地域排序换位置</a
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
import { reactive, watch } from 'vue'
const emit = defineEmits(['update:value'])
import baseService from '@/utils/http/axios'
import { message } from 'ant-design-vue'
import { trimAll } from '@/utils/util'
let timer: any = null
const props = defineProps({
  value: {
    type: Object
  },
  principalType: {
    type: Number
  },
  business_type: {
    type: Number
  },
  isFiliale: {
    type: Number
  }
})
const state = reactive<any>({
  options: [],
  name_division: '',
  full_name: '',
  name_division_position: 0,
  filiale_full_name: '',
  name_word_mark: '',
  ondustry_representation: undefined,
  organizational_form: '',
  ondustry_representation_type: null,
  ondustry_representation_id: null,
  isFirst: true,
  // 组织形式
  optionList: ['有限公司', '有限责任公司', '集团有限公司', '中心（有限合伙）', '合伙企业（有限合伙）', '分公司', '分店'],
  organizationalFormList: [
    '经营部',
    '服务部',
    '商行',
    '店',
    '部',
    '工作室',
    '铺',
    '馆',
    '坊',
    '场',
    '门市部',
    '大药房',
    '药房',
    '药店',
    '厅',
    '行',
    '房',
    '社',
    '亭',
    '网咖',
    '网吧',
    '处',
    '厂',
    '咖啡厅',
    '咖啡店',
    '中心',
    '饭店',
    '饭堂',
    '吧',
    '商店',
    '商店馆',
    '浴池',
    '诊所',
    '门诊部',
    '俱乐部',
    '酒吧',
    '酒店',
    '旅店',
    '农家院',
    '农场',
    '影楼',
    '加盟店',
    '队',
    '超市',
    '家庭农场',
    '冷库',
    '餐厅',
    '苗圃',
    '医院',
    '屋',
    '广场',
    '城'
  ],
  organizationalFormList_GD: [
    '经营部',
    '部',
    '商行',
    '工作室',
    '店',
    '商店',
    '铺',
    '馆',
    '坊',
    '场',
    '门市部',
    '大药房',
    '药房',
    '药店',
    '厅',
    '行',
    '房',
    '社',
    '亭',
    '网咖',
    '网吧',
    '处',
    '厂',
    '咖啡厅',
    '咖啡店',
    '中心',
    '饭店',
    '饭堂',
    '中心',
    '吧',
    '商店馆',
    '浴池',
    '诊所',
    '门诊部',
    '馆',
    '俱乐部',
    '酒吧',
    '酒店',
    '旅店',
    '农家院',
    '农场',
    '影楼',
    '加盟店',
    '队',
    '超市',
    '家庭农场',
    '冷库',
    '餐厅',
    '苗圃',
    '医院',
    '屋',
    '广场',
    '站'
  ],
  hehuoList: ['合伙企业（有限合伙）', '合伙企业（普通合伙）'] //  合伙企业
})

watch(
  () => props.value,
  (val) => {
    if (val) {
      state.name_division = val.name_division || ''
      state.filiale_full_name = val.filiale_full_name || null
      state.name_word_mark = val.name_word_mark || ''
      state.ondustry_representation = val.ondustry_representation || ''
      state.ondustry_representation_type = val.ondustry_representation_type || null
      state.ondustry_representation_id = val.ondustry_representation_id || null
      state.organizational_form = val.organizational_form || ''
      state.name_division_position = val.name_division_position || 0
      state.full_name = val.full_name || ''

      if (val.ondustry_representation) {
        if (!state.isFirst) return
        state.isFirst = false
        handleSearch(val.ondustry_representation)
      }
    }
  },
  { deep: true }
)
watch(
  () => props.principalType,
  (val) => {
    state.ondustry_representation = ''
    state.ondustry_representation_type = null
    state.ondustry_representation_id = null
    state.options = []
    if (val == 1) {
      state.organizational_form = '有限公司'
    } else {
      state.organizational_form = null
    }
    triggerChange()
  },
  { deep: true }
)
const change_zihao_postion = () => {
  state.name_division_position += 1
  if (state.name_division_position == 3) {
    state.name_division_position = 0
  }
  triggerChange()
}
const inputChange = (value, type) => {
  if (type == 'name_division') {
    value.target.value = value.target.value.replace('巿', '市')
  }
  value.target.value = trimAll(value.target.value)
  state[type] = value.target.value
  triggerChange()
}
const selectChange = (value, type) => {
  if (props.principalType == 1) {
    if (value == '分公司' || state[type] == '分公司' || value == '分店' || state[type] == '分店') {
      state.name_division = ''
      state.ondustry_representation = ''
    }
  }
  state[type] = value
  triggerChange()
}
const triggerChange = () => {
  let full_name = ''
  if (props.isFiliale == 1) {
    full_name = `${state.filiale_full_name}${state.name_word_mark}${state.organizational_form}`
  } else {
    //公司需要根据位置
    if (state.name_division_position == 0) {
      full_name = state.name_division + state.name_word_mark + state.ondustry_representation + state.organizational_form
    } else if (state.name_division_position == 1) {
      full_name = state.name_word_mark + '（' + state.name_division + '）' + state.ondustry_representation + state.organizational_form
    } else {
      full_name = state.name_word_mark + state.ondustry_representation + '（' + state.name_division + '）' + state.organizational_form
    }
    full_name = `${full_name}${props.principalType == 2 ? '（个体工商户）' : props.principalType == 3 ? '（个人独资）' : ''}`
  }

  emit('update:value', {
    name_division: state.name_division,
    filiale_full_name: state.filiale_full_name,
    name_word_mark: state.name_word_mark,
    ondustry_representation: state.ondustry_representation,
    organizational_form: state.organizational_form,
    ondustry_representation_type: state.ondustry_representation_type,
    ondustry_representation_id: state.ondustry_representation_id,
    name_division_position: state.name_division_position,
    full_name: full_name
  })
}
const handleSearch = (val: string) => {
  if (props.business_type != 1) {
    state.options = [
      {
        id: val,
        keyname: val,
        keyvalue: val
      }
    ]
    return
  }
  clearTimeout(timer)
  if (val == '') {
    state.options = []
    return
  }
  // 必须中文
  var reg = /^[\u4E00-\u9FA5]+$/
  if (!reg.test(val)) {
    return
  }
  if (val.includes('有限公司')) {
    val = val.replace('有限公司', '')
  } else if (val.includes('有限责任公司')) {
    val = val.replace('有限责任公司', '')
  }
  timer = setTimeout(() => {
    if (props.principalType == 1 || props.principalType == 3) {
      let requestParam = {
        url: '/admin/v1/getCompanyStep?keys=' + val,
        method: 'get'
      }
      baseService(requestParam)
        .then((res) => {
          if (!res.data.some((res) => res.keyname === val)) {
            // res.data.unshift({
            //   keyname: val,
            //   id: val,
            //   keyvalue: val
            // })
          }
          state.options = res.data
        })
        .catch((res) => {
          if (res.msg) {
            message.error(res.msg)
          } else {
            message.error('操作失败')
          }
        })
    } else {
      let requestParam = {
        url: '/admin/v1/getIndividualStep?keys=' + val,
        method: 'get'
      }
      baseService(requestParam)
        .then((res) => {
          if (!res.data.some((res) => res.keyname === val)) {
            // res.data.unshift({
            //   keyname: val,
            //   id: val,
            //   keyvalue: val
            // })
          }
          state.options = res.data
        })
        .catch((res) => {
          if (res.msg) {
            message.error(res.msg)
          } else {
            message.error('操作失败')
          }
        })
    }
  }, 500)
}
// 复制名称
const handleNameClick = (event) => {
  var input = document.createElement('input')
  //将input的值设置为需要复制的内容
  input.value = event.target.innerHTML
  //添加input标签
  document.body.appendChild(input)
  //选中input标签
  input.select()
  //执行复制
  document.execCommand('copy')
  //成功提示信息
  message.success('复制成功，尝试双击有惊喜！')
  //移除input标签
  document.body.removeChild(input)
}
const handleChange = (val, obj) => {
  state.ondustry_representation = obj.label
  state.ondustry_representation_type = obj.title
  state.ondustry_representation_id = obj.value
  triggerChange()
}
defineExpose({
  inputChange
})
</script>

<style lang="less" scoped>
.d-flex {
  justify-content: space-between;
}

.top {
  color: #1890ff;
  display: none;
  cursor: pointer;
}

.d-flex:hover {
  .top {
    display: block;
  }
}
</style>
<style lang="less">
.principalName {
  width: 500px !important;
  margin-left: -70px !important;
}
</style>
